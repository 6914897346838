/* eslint-disable no-unused-vars */
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import {
  HomeContent,
  SportContent,
  EconomyContent,
  TeknologiContent,
  HealthContent,
  CelebrityContent,
  ScienceContent,
  BookmarkContent
} from './components/molecule/content';
import { ScrollToTop } from './util';
import { Home, Login, Page, Community, Profile } from './view';
import Register from './view/register';

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />

        <Routes>
          <Route path="*" element={<h1>asdads</h1>} />

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
          {/* <Route path="/profile" element={<Profile />}> */}
          <Route path="/" element={<Home />}>
            <Route index element={<HomeContent />} />
            <Route path="bookmark" element={<BookmarkContent />} />
            <Route path="berita" element={<HomeContent />} />
            <Route path="sport" element={<SportContent />} />

            <Route path="economy" element={<EconomyContent />} />
            <Route path="teknologi" element={<TeknologiContent />} />
            <Route path="health" element={<HealthContent />} />
            <Route path="celebrity" element={<CelebrityContent />} />
            <Route path="science" element={<ScienceContent />} />
          </Route>

          <Route path="/community" element={<Community />}>
            {/* <Route index element={<HomeContent />} /> */}
            {/* <Route path="berita" element={<HomeContent />} />
            <Route path="sport" element={<SportContent />} />
            <Route path="economy" element={<EconomyContent />} />
            <Route path="teknologi" element={<TeknologiContent />} />
            <Route path="health" element={<HealthContent />} />
            <Route path="celebrity" element={<CelebrityContent />} />
            <Route path="science" element={<ScienceContent />} /> */}
          </Route>

          <Route path="/page" element={<Page />}>
            <Route
              path="disclaimer"
              element={
                <>
                  {' '}
                  <h1 className="mb-3 text-3xl font-bold tracking-tight text-gray-900 ">
                    Disclaimer
                  </h1>
                  <p className=" text-xl  text-gray-700 ">
                    Seluruh data berita yang ada di website ini berasal dari{' '}
                    <a href="https://newsapi.org/">newsapi.org</a> kami tidak menyimpan data news
                    tersebut hanya untuk kepentingan performa dan bookmark. seluruh kesalahan
                    penulisan atau hak cipta atas konten berita bukan merupakan tanggung jawab kami
                  </p>
                </>
              }
            />
            <Route
              path="privacy"
              element={
                <>
                  {' '}
                  <h1 className="mb-3 text-3xl font-bold tracking-tight text-gray-900 ">
                    Kebijakan Privacy
                  </h1>
                  <p className=" text-xl  text-gray-700 ">
                    Kami tim Ternak Uang sangat peduli terhadap privasimu saat mengunjungi,
                    mendaftar, memberikan informasi, maupun menggunakan website/apliaksi maupun
                    menggunakan layanan Kami sehubungan dengan penggunaan website/apliaksi tersebut.
                    Kebijakan Privasi ini merupakan bagian yang tidak terpisahkan dari Ketentuan
                    Layanan, yang khusus menjelaskan bagaimana Kami menggunakan informasi pribadimu
                    yang Kamu berikan, Kami kumpulkan dan/atau Kami terima dari sumber lain
                    (“Informasi Pribadi”). Mohon membaca Kebijakan Privasi Kami dengan seksama
                    sehingga Kamu dapat memahami pendekatan dan cara Kami dalam menggunakan
                    informasi tersebut. Dengan mengunjungi, mendaftar, memberikan informasi, maupun
                    menggunakan setiap dan seluruh fitur dari dan layanan yang terkait dengan
                    Portal, maka Kamu telah menyetujui Kebijakan Privasi ini. Jika Kamu tidak setuju
                    dengan Kebijakan Privasi ini, mohon untuk tidak mengunjungi, mendaftar,
                    memberikan informasi, maupun menggunakan fitur apapun pada website/apliaksi.
                    <br />
                    1. Persetujuan
                    <br />
                    Dengan mengunjungi, mendaftar, memberikan informasi, menggunakan
                    website/apliaksi, menggunakan layanan Kami terkait website/apliaksi, maupun
                    berkorespondensi dengan Kami dengan media apapun juga, maka Kamu menyetujui
                    bahwa Kami dapat mengumpulkan Informasi Pribadi untuk kegunaan Kami dalam bentuk
                    pemasaran maupun monetisasi data dengan pihak luar perusahaan. Informasi Pribadi
                    yang Dapat Kami Kumpulkan Informasi Pribadi yang Dapat Kami Kumpulkan adalah
                    sebagai berikut:
                    <br />
                    2. Informasi yang Kamu Berikan
                    <br />
                    Kamu dapat memberikan informasi melalui formulir elektronik pada
                    website/apliaksi Kami maupun dengan berkorespondensi melalui telepon, surat
                    elektronik, media sosial, maupun dengan metode lainnya. Informasi ini termasuk
                    namun tidak terbatas pada informasi yang Kamu berikan ketika mengisi survei
                    (baik melalui website/apliaksi, surat elektronik, sosial media, maupun dengan
                    metode lainnya), mendaftar pada website/apliaksi, menggunakan setiap dan seluruh
                    fitur website/apliaksi, berlangganan layanan Kami, berdiskusi dengan Kami,
                    informasi sehubungan dengan permohonan refund atas biaya berlangganan pada
                    website/apliaksi Kami, testimoni, dan sebagainya. Informasi yang Kamu berikan
                    dapat meliputi nama, alamat surat elektronik, informasi seputar media data diri
                    kamu, informasi nomor telepon, surat elektronik dan data lainnya. Kami dapat
                    meminta Kamu untuk melakukan verifikasi terhadap informasi yang Kamu berikan,
                    untuk memastikan akurasi dari informasi tersebut.
                    <br />
                    3. Informasi yang Kami Terima dari Sumber Lain
                    <br />
                    Kami juga dapat mengumpulkan informasi pribadi melalui teknologi deteksi lainnya
                    yang umum digunakan pada situs web, surat elektronik, maupun media sosial. Kami
                    juga dapat bekerja sama dengan pihak ketiga (termasuk, namun tidak terbatas pada
                    misalnya, mitra bisnis, sub-kontraktor dalam pelayanan teknis, jasa pembayaran,
                    jaringan periklanan, penyedia analisis, penyedia pencarian informasi) (“Mitra
                    Pihak Ketiga”) dan dapat menerima Informasi Pribadi dari mereka.
                    <br />
                    4. Informasi yang Dikecualikan
                    <br />
                    Kami bekerja sama dengan penyedia layanan payment gateway pihak ketiga
                    sehubungan dengan pembayaran yang Kamu lakukan Ketika berlangganan layanan Kami
                    melalui website/apliaksi. Selain dari informasi ringkasan transaksi yang Kami
                    dapatkan dari penyedia layanan payment gateway, Kami tidak mengumpulkan maupun
                    menyimpan informasi pembayaran (misalnya informasi mengenai kartu kredit) yang
                    Kamu masukkan melalui sistem yang disediakan masing-masing layanan payment
                    gateway.Setiap informasi pembayaran yang Kamu berikan saat melakukan pembayaran
                    melalui layanan payment gateway merupakan informasi yang dikecualikan dari
                    Kebijakan Privasi ini dan tunduk pada kebijakan privasi dari masing-masing
                    penyedia layanan payment gateway. Kami tidak bertanggung jawab atas kebijakan
                    privasi dari setiap penyedia layanan payment gateway. Sebagaimana dijelaskan di
                    atas, Kami juga dapat bekerja sama dengan Mitra Pihak Ketiga dalam menyediakan
                    layanan Portal. Setiap dan seluruh informasi yang Kamu berikan kepada Mitra
                    Pihak Ketiga sehubungan dengan hal tersebut merupakan informasi yang
                    dikecualikan dari Kebijakan Privasi ini dan tunduk pada kebijakan privasi dari
                    masing-masing penyedia layanan pihak ketiga. Untuk menghindari keraguan, Kami
                    tidak bertanggung jawab untuk setiap dan seluruh penyalahgunaan informasi yang
                    dilakukan oleh penyedia layanan pihak ketiga.
                    <br />
                    5. Penyimpanan Informasi Pribadi
                    <br />
                    Kami dapat menggunakan layanan pihak ketiga, baik yang disediakan di dalam
                    maupun di luar negeri, untuk melakukan penyimpanan Informasi Pribadi, dalam hal
                    mana Kami akan mengambil langkah-langkah yang wajar dan komersil untuk
                    memastikan bahwa Informasi Pribadi tersebut disimpan dengan aman dan sesuai
                    dengan Kebijakan Privasi serta hukum yang berlaku (termasuk dengan cara menunjuk
                    pihak ketiga yang kredibel untuk melakukan penyimpanan hal tersebut). Kami
                    dapat, sewaktu-waktu sesuai dengan direksi Kami, menghapus permanen Informasi
                    Pribadi yang Kami simpan. Ketentuan penyimpanan Informasi Pribadi berdasarkan
                    bagian ini dimulai saat Kami telah menerima Informasi Pribadimu. Adapun seluruh
                    risiko atas transmisi Informasi Pribadi yang Kamu lakukan berada di luar kuasa
                    Kami dan bukan merupakan tanggung jawab Kami, untuk itu, mohon untuk
                    memperhatikan keamanan atas transmisi informasi melalui internet pada perangkat
                    maupun jaringan internet yang Kamu pergunakan.
                    <br />
                    6. Hak Kamu atas Informasi Pribadi
                    <br />
                    Kamu memiliki hak-hak berikut atas Informasi Pribadi: Mengubah Informasi Pribadi
                    tertentu melalui website/apliaksi sesuai dengan prosedur yang telah Kami
                    sediakan; Memilih untuk tidak menerima komunikasi pemasaran dari Kami dengan
                    menggunakan opsi yang Kami sediakan pada website/apliaksi maupun pada surat
                    elektronik, dengan catatan, Kami tetap dapat menghubungi Kamu untuk keperluan
                    non-promosi, seperti memberikan tanda terima atau meminta informasi tentang
                    penggunaan website/apliaksi; Memohon untuk penghapusan Informasi Pribadi yang
                    Kami simpan, dengan memberikan kepada Kami pemberitahuan yang wajar secara
                    tertulis melalui kontak yang tercantum pada bagian akhir Kebijakan Privasi ini.
                    Kamu sepenuhnya mengerti dan mengakui bahwa, bergantung pada keadaan atau sifat
                    serta penggunaan Informasi Pribadi oleh Kami: (i) Kamu mungkin tidak dapat lagi
                    menggunakan layanan website/apliaksi, (ii) Informasi Pribadi yang telah
                    diungkapkan kepada dan beredar luas di publik sebagai bagian dari pemasaran
                    (misalnya, testimoni) tidak dapat dihapus, (iii) Agregat dari informasi, hasil
                    analisis, statistik, maupun bentuk-bentuk informasi lainnya yang tidak
                    mengandung informasi personal dirimu merupakan bagian yang dapat terus Kami
                    pergunakan sesuai ketentuan Kebijakan Privasi ini.
                    <br />
                    7. Perubahan dalam Kebijakan Privasi
                    <br />
                    Kamu mengakui dan menyetujui bahwa Kami dapat melakukan perubahan sebagaimana
                    diperlukan dan/atau sebagaimana diwajibkan oleh hukum yang berlaku, terhadap
                    Kebijakan Privasi ini. Sebagai konsekuensi, Kamu setuju untuk tunduk pada setiap
                    perubahan Kebijakan Privasi. Perubahan apapun yang Kami lakukan terhadap
                    Kebijakan Privasi Kami di masa mendatang akan diterbitkan melalui halaman ini
                    dan, ketika dibutuhkan, diberitahukan kepada Kamu melalui surat elektronik.
                    Mohon kunjungi kembali halaman ini dari waktu ke waktu untuk melihat adanya
                    perubahan pada Kebijakan Privasi ini.
                    {/* CARA MENGHUBUNGI KAMI
Kamu dapat menghubungi Kami melalui cs@ternakuang.id jika Kamu memiliki pertanyaan mengenai Kebijakan Privasi ini dan/atau melakukan koreksi terhadap Informasi Pribadi Kamu. */}
                    {/* Seluruh data berita yang ada di website ini berasal dari <a href="https://newsapi.org/">newsapi.org</a> kami tidak menyimpan data news tersebut hanya untuk kepentingan performa dan bookmark. seluruh kesalahan penulisan atau hak cipta atas konten berita bukan merupakan tanggung jawab kami  */}
                  </p>
                </>
              }
            />
            <Route
              path="kontak"
              element={
                <>
                  {' '}
                  <h1 className="mb-3 text-3xl font-bold tracking-tight text-gray-900 ">
                    Hubungi Kami
                  </h1>
                  <p className=" text-xl  text-gray-700 ">email : Hajidalakhtar@gmail.com</p>
                </>
              }
            />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

// const Search = () => {
//   return (
//     <div className='Search'>
//       <h2>You are inside the Search Component</h2>
//       <h4>URL: localhost:3000/courses/search</h4>
//     </div>
//   )
// }

// const List = () => {
//   return (
//     <div className='List'>
//       <h2>You are inside the List Component</h2>
//       <h4>URL: localhost:3000/courses/list</h4>
//     </div>
//   )
// }

// const Home = () => {
//   return (
//     <div className="Page">
//       <h1>You are in the Home page!</h1>
//       <h3>URL: localhost:3000/</h3>
//     </div>
//   )
// }

// const Courses = () => {
//   return (
//     <div className="Page">
//       <h1>You are in the Courses page!</h1>
//       <h3>URL: localhost:3000/courses</h3>
//       <div className="courses-nav">
//         <Link to="/courses/search">Search</Link>
//         <Link to="/courses/list">List</Link>
//       </div>
//       <Outlet />
//     </div>
//   )
// }

export default App;
