/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-key */
// import { useFeedStore } from '../../../../store/index';
// import { useEffect, useState, useRef } from "react";
import React from 'react';
import { useUserStore } from '../../../../store/user';
import About from '../about';
import Badges from '../badges';
import CardPost from '../card_post';
// import CardCommunity from '../../card_community';

function ProfileContent() {
  const user = useUserStore((state) => state.user);

  return (
    <>
      <div className="p-5 border">
        {/* <h1>PROFILE</h1> */}
        <div className=" mt-4 max-w-6xl mx-auto ">
          <div className="grid grid-cols-12   ">
            <div className=" col-span-2 visible ">
              <img
                className="p-1 w-36 h-36 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                src={`https://avatars.dicebear.com/api/miniavs/${user.username}.svg`}
                alt="Rounded avatar"
              />
            </div>
            <div className=" mt-3 col-span-10  ">
              <div className="w-full">
                <p className="text-logo font-bold" style={{ fontSize: '27px' }}>
                  {user.username}
                </p>
                <div className="inline-flex" role="alert">
                  <img
                    src="https://img.icons8.com/windows/32/000000/marker.png"
                    className="w-5 h-5 mt-0.5"
                    alt="Tranding"
                  />
                  <p className="font-semibold text-md">Depok</p>
                </div>

                <div className="grid grid-flow-col mt-3 auto-cols-max">
                  <p>
                    <span className="font-bold">4 </span>Followers •{' '}
                    <span className="font-bold">1</span> Following
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5 grid grid-cols-12 gap-3">
        <div className=" col-span-3">
          <About />
          <Badges />
        </div>
        <div className="p-5 border col-span-9">
          <ul class="flex border-b border-gray-100">
            <li class="flex-1">
              <a class="relative block p-4" href="">
                <span class="absolute inset-x-0 w-full h-px bg-slate-600 -bottom-px"></span>

                <div class="flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 w-5 h-5 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                    />
                  </svg>

                  <span class="ml-3 text-sm font-medium text-gray-900"> Post </span>
                </div>
              </a>
            </li>

            <li class="flex-1">
              <a class="relative block p-4" href="">
                <div class="flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 w-5 h-5 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                    />
                  </svg>

                  <span class="ml-3 text-sm font-medium text-gray-900"> Messages </span>
                </div>
              </a>
            </li>

            <li class="flex-1">
              <a class="relative block p-4" href="">
                <div class="flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 w-5 h-5 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                    />
                  </svg>

                  <span class="ml-3 text-sm font-medium text-gray-900"> Archive </span>
                </div>
              </a>
            </li>

            <li class="flex-1">
              <a class="relative block p-4" href="">
                <div class="flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 w-5 h-5 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>

                  <span class="ml-3 text-sm font-medium text-gray-900"> Notifications </span>
                </div>
              </a>
            </li>
          </ul>

          <div className="mt-4">
            <CardPost />
            <CardPost />
            <CardPost />
            <CardPost />
            <CardPost />
            <CardPost />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileContent;
