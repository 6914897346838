function About() {
  return (
    <div className="p-5 border">
      <div className=" inline-flex mb-3">
        <div className={'inline-flex w-52  rounded-full '} role="alert">
          <p className="font-semibold text-md">About</p>
          <img
            src="https://img.icons8.com/emoji/48/undefined/fire.png"
            className="w-5"
            alt="Tranding"
          />
        </div>
      </div>
      Looking to get into development? As a full-stack developer I guide you on this journey and
      give you bite sized tips every single day 👊
    </div>
  );
}

export default About;
