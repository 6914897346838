/* eslint-disable react/prop-types */
function CardRightbarSkeleton() {
  return (
    <>
      <a
        target="_blank"
        className="block p-3 mb-3  bg-white rounded-lg border border-gray-200 hover:bg-gray-100"
        style={{ maxWidth: 295 }}
        rel="noreferrer">
        {/* <h5 className="mb-1 text-sm font-bold tracking-tight text-gray-900 ">asdasd</h5>
         */}
        <div className="bg-gray-200  w-100 animate-pulse h-5 rounded-xl mb-1" />
        <div className="bg-gray-200  w-100 animate-pulse h-10  rounded-xl" />

        {/* <p className=" text-sm  text-gray-700 text-ellipsis overflow-hidden ">asdasd</p> */}
      </a>
    </>
  );
}

export default CardRightbarSkeleton;
