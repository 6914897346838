/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
import { HeaderContent } from '../../../atom';
import CardContent from '../../card_content';
import { useFeedStore } from '../../../../store/index';
// import { useEffect, useState, useRef } from "react";
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import CardSkeleton from '../../card_skeloton';
import { useOutletContext } from 'react-router-dom';
import Api from '../../../../util/api';
import moment from 'moment';
import Auth from '../../../../util/auth';
import Cache from '../../../../util/Cache';

function EconomyContent() {
  const api = new Api();
  const cache = new Cache();
  const auth = new Auth();
  const [page, setPage] = useState(1);
  const [isAuth, _] = useState(auth.isAuth());
  const [feed, setFeed] = useState([]);
  const addFeed = useFeedStore((state) => state.addFeedEconomy);
  const refreshFeedEconomy = useFeedStore((state) => state.refreshFeedEconomy);
  const updateLastFeed = useFeedStore((state) => state.updateLastFeed);
  const lastFeed = useFeedStore((state) => state.lastFeed);
  const feed_check = useFeedStore((state) => state.feedEconomy.length === 0);

  const feedNewsData = async () => {
    var feedData = await cache.getFeedEconomy(page, addFeed, updateLastFeed);
    setFeed(feedData);
    setPage(page + 1);
  };

  useEffect(() => {
    if (moment(lastFeed).add(2, 'hours') <= moment() || feed_check) {
      api.getNewsList('business', { page: page }).then(function (response) {
        refreshFeedEconomy(response.data.data);
        updateLastFeed();
        setFeed(response.data.data);
        setPage(page + 1);
      });
    } else {
      feedNewsData();
    }
  }, []);
  return (
    <>
      {feed.length === 0 ? (
        <>
          <CardSkeleton /> <CardSkeleton /> <CardSkeleton /> <CardSkeleton />
        </>
      ) : (
        <InfiniteScroll
          dataLength={feed.length}
          next={feedNewsData}
          hasMore
          loader={
            <div className="text-center mt-10 mb-10">
              <h4 className="font-bold text-xl">Loading...</h4>
            </div>
          }
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          }>
          {feed.map((item) => (
            <CardContent
              isAuth={isAuth}
              key={item.entityId}
              upvote_user={item.upvote_user}
              downvote_user={item.downvote_user}
              id={item.entityId}
              link={item.url}
              vote={item.vote}
              source_name={item.source}
              description={item.description}
              title={item.title}
              thumbnail={item.urlToImage}
              categories={'Economy'}
              date={item.publishedAt}
            />
          ))}
        </InfiniteScroll>
      )}
    </>
  );
}

export default EconomyContent;
