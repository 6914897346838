function CardSkeleton() {
  return (
    <div className="flex ">
      <div className="border border-b-4 p-5  w-full bg-white hover:bg-gray-100">
        <div className="flex w-full items-center justify-between border-b pb-3">
          <div className="flex items-center space-x-2">
            <div className={'h-7 w-7 rounded-full bg-gray-200  bg-cover'} />
            <div className="text-md font-bold text-slate-700">
              <div className="bg-gray-200  w-20 animate-pulse h-5 rounded-2xl" />
            </div>
          </div>
          <div className="flex items-center space-x-2">
            {/* {props.categories} */}
            <div className="bg-gray-200  w-20 animate-pulse h-5 rounded-2xl" />

            <div className="bg-gray-200  w-20 animate-pulse h-5 rounded-2xl" />
          </div>
        </div>

        <div className="mt-4 mb-6">
          {/* <div class="mb-3 text-lg font-bold">{props.title}</div> */}
          <div className="mb-3 bg-gray-200 w-full animate-pulse h-6 rounded-2xl" />

          <div>
            {/* <div class="h-52 sm:h-full sm:w-72 rounded-xl bg-gray-200 animate-pulse"></div> */}
            <div className="bg-gray-200 w-full animate-pulse h-80 rounded-2xl" />

            {/* <img class="rounded-md w-full" src={props.thumbnail} /> */}
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between text-slate-500">
            <div className="flex space-x-4 md:space-x-8">
              <div className="flex cursor-pointer items-center transition hover:text-slate-600">
                <div className="bg-gray-200  w-20 animate-pulse h-5 rounded-2xl" />
              </div>
              <div className="flex cursor-pointer items-center transition hover:text-slate-600">
                <div className="bg-gray-200  w-20 animate-pulse h-5 rounded-2xl" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div class="bg-white p-2 sm:p-4 sm:h-64 rounded-2xl shadow-lg flex flex-col sm:flex-row gap-5 select-none ">
    //   <div class="h-52 sm:h-full sm:w-72 rounded-xl bg-gray-200 animate-pulse"></div>
    //   <div class="flex flex-col flex-1 gap-5 sm:p-2">
    //     <div class="flex flex-1 flex-col gap-3">
    //       <div class="bg-gray-200 w-full animate-pulse h-14 rounded-2xl"></div>
    //       <div class="bg-gray-200 w-full animate-pulse h-3 rounded-2xl"></div>
    //       <div class="bg-gray-200 w-full animate-pulse h-3 rounded-2xl"></div>
    //       <div class="bg-gray-200 w-full animate-pulse h-3 rounded-2xl"></div>
    //       <div class="bg-gray-200 w-full animate-pulse h-3 rounded-2xl"></div>
    //     </div>
    //     <div class="mt-auto flex gap-3">
    //       <div class="bg-gray-200 w-20 h-8 animate-pulse rounded-full"></div>
    //       <div class="bg-gray-200 w-20 h-8 animate-pulse rounded-full"></div>
    //       <div class="bg-gray-200 w-20 h-8 animate-pulse rounded-full ml-auto"></div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default CardSkeleton;
