/* eslint-disable no-unused-vars */
import moment from 'moment';
import create from 'zustand';
import { persist } from 'zustand/middleware';

const useStore = create(
  persist((set, get) => ({
    feedBerita: [],
    feedSport: [],
    feedEconomy: [],
    feedTechnology: [],
    feedScience: [],
    feedEntertainment: [],
    feedHealth: [],

    lastFeed: null,
    addFeedBerita: (data) => {
      set((state) => ({
        feedBerita: [...state.feedBerita, ...data]
      }));
    },

    addFeedSport: (data) => {
      set((state) => ({
        feedSport: [...state.feedSport, ...data]
      }));
    },

    addFeedEconomy: (data) => {
      set((state) => ({
        feedEconomy: [...state.feedEconomy, ...data]
      }));
    },

    addFeedTechnology: (data) => {
      set((state) => ({
        feedTechnology: [...state.feedTechnology, ...data]
      }));
    },
    addFeedScience: (data) => {
      set((state) => ({
        feedScience: [...state.feedScience, ...data]
      }));
    },

    addFeedEntertainment: (data) => {
      set((state) => ({
        feedEntertainment: [...state.feedEntertainment, ...data]
      }));
    },

    addFeedHealth: (data) => {
      set((state) => ({
        feedHealth: [...state.feedHealth, ...data]
      }));
    },

    updateLastFeed: (data) => {
      set((state) => ({
        lastFeed: moment()
      }));
    },

    refreshFeedBerita: (data) => {
      set((state) => ({
        feedBerita: data
      }));
    },

    refreshFeedSport: (data) => {
      set((state) => ({
        feedSport: data
      }));
    },
    refreshFeedEconomy: (data) => {
      set((state) => ({
        feedEconomy: data
      }));
    },

    refreshFeedTechnology: (data) => {
      set((state) => ({
        feedTechnology: data
      }));
    },

    refreshFeedScience: (data) => {
      set((state) => ({
        feedScience: data
      }));
    },

    refreshFeedEntertainment: (data) => {
      set((state) => ({
        feedEntertainment: data
      }));
    },

    refreshFeedHealth: (data) => {
      set((state) => ({
        feedHealth: data
      }));
    }
  }))
);
// const useStore = create((set) => ({
//   feedBerita: [],
//   feedSport: [],

//   addFeedBerita: (data) => {
//     set((state) => ({
//       feedBerita: [...state.feedBerita, ...data]
//     }));
//   },

//   // addFeedSport: (data) => {
//   //   set((state) => ({
//   //     feedSport: [...state.feedSport, ...data]
//   //   }));
//   // },

//   removeFeed: (data) => {
//     set((state) => ({
//       feed: []
//     }));
//   }
// }));

export const useFeedStore = useStore;
