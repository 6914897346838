/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CardRightSitebar, RightSitebar } from '../../atom';
import axios from 'axios';
import Api from '../../../util/api';
import CardRightbarSkeleton from '../card_rightbar_skeloton';

function RightBar() {
  const [tranding, setTranding] = useState([]);
  const api = new Api();
  const feedNewsData = async () => {
    api.getTrandingList().then(function (response) {
      setTranding(response.data.data);
    });
  };

  useEffect(() => {
    feedNewsData();
  }, []);

  return (
    <>
      <div className="mt-5 fixed ">
        <div className=" inline-flex mb-3">
          <div className={'inline-flex w-52  rounded-full '} role="alert">
            <p className="font-semibold text-md">Tranding</p>
            <img
              src="https://img.icons8.com/emoji/48/undefined/fire.png"
              className="w-5"
              alt="Tranding"
            />
          </div>
        </div>
        {tranding.length != 0 ? (
          tranding.map((feed) => (
            <CardRightSitebar
              key={feed.entityId}
              source={feed.source}
              url={feed.url}
              body={feed.title}
            />
          ))
        ) : (
          <>
            {' '}
            <CardRightbarSkeleton />
            <CardRightbarSkeleton />
            <CardRightbarSkeleton />
            <CardRightbarSkeleton />
            <CardRightbarSkeleton />
          </>
        )}

        <div>
          <span className="ml-2 font-medium text-xs pr-5 text-gray-400">Kebijakan Privasi</span>
          <span className="font-medium text-xs pr-5 text-gray-400">Syarat & Ketentuan</span>
          <br />
          <Link to={`/page/kontak`}>
            <span className="ml-2 font-medium text-xs pr-5 text-gray-400">Hubungi Kami</span>
          </Link>

          <Link to={`/page/disclaimer`}>
            <span className="font-medium text-xs pr-5 text-gray-400">Disclaimer</span>
          </Link>

          <br />

          <p className=" ml-3 mt-3 text-sm font-semibold text-gray-400">© 2022 BEBASINFO</p>
        </div>
      </div>
    </>
  );
}

export default RightBar;
