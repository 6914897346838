/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import moment from 'moment';
import { useFeedStore } from '../store';
import Api from './api';

//TODO:chache belom di hapus ketika sudah satu jam

export default class Cache {
  constructor() {
    this.feedBerita = useFeedStore((state) => state.feedBerita);
    this.feedSport = useFeedStore((state) => state.feedSport);
    this.feedEconomy = useFeedStore((state) => state.feedEconomy);
    this.feedTechnology = useFeedStore((state) => state.feedTechnology);
    this.feedScience = useFeedStore((state) => state.feedScience);
    this.feedEntertainment = useFeedStore((state) => state.feedEntertainment);
    this.feedHealth = useFeedStore((state) => state.feedHealth);

    this.lastFeed = useFeedStore((state) => state.lastFeed);

    this.api = new Api();
  }

  getFeedBerita = async (page, addFeed, updateLastFeed) => {
    let result = this.feedBerita.slice(0, page * 10);
    if (page * 10 > this.feedBerita.length || this.feedBerita.length === 0) {
      await this.api.getNewsList('general', { page: page }).then(function (response) {
        addFeed(response.data.data);
        updateLastFeed();
        result.push(response.data.data);
      });
    }
    return result;
  };

  getFeedSport = async (page, addFeed, updateLastFeed) => {
    let result = this.feedSport.slice(0, page * 10);
    if (page * 10 > this.feedSport.length || this.feedSport.length === 0) {
      await this.api.getNewsList('sports', { page: page }).then(function (response) {
        addFeed(response.data.data);
        updateLastFeed();
        result.push(response.data.data);
      });
    }
    return result;
  };

  getFeedEconomy = async (page, addFeed, updateLastFeed) => {
    let result = this.feedEconomy.slice(0, page * 10);
    if (page * 10 > this.feedEconomy.length || this.feedEconomy.length === 0) {
      await this.api.getNewsList('business', { page: page }).then(function (response) {
        addFeed(response.data.data);
        updateLastFeed();
        result.push(response.data.data);
      });
    }
    return result;
  };

  getFeedTechnology = async (page, addFeed, updateLastFeed) => {
    let result = this.feedTechnology.slice(0, page * 10);
    if (page * 10 > this.feedTechnology.length || this.feedTechnology.length === 0) {
      await this.api.getNewsList('technology', { page: page }).then(function (response) {
        addFeed(response.data.data);
        updateLastFeed();
        result.push(response.data.data);
      });
    }
    return result;
  };

  getFeedScience = async (page, addFeed, updateLastFeed) => {
    let result = this.feedScience.slice(0, page * 10);
    if (page * 10 > this.feedScience.length || this.feedScience.length === 0) {
      await this.api.getNewsList('science', { page: page }).then(function (response) {
        addFeed(response.data.data);
        updateLastFeed();
        result.push(response.data.data);
      });
    }
    return result;
  };

  getFeedEntertainment = async (page, addFeed, updateLastFeed) => {
    let result = this.feedEntertainment.slice(0, page * 10);
    if (page * 10 > this.feedEntertainment.length || this.feedEntertainment.length === 0) {
      await this.api.getNewsList('entertainment', { page: page }).then(function (response) {
        addFeed(response.data.data);
        updateLastFeed();
        result.push(response.data.data);
      });
    }
    return result;
  };

  getFeedHealth = async (page, addFeed, updateLastFeed) => {
    let result = this.feedHealth.slice(0, page * 10);
    if (page * 10 > this.feedHealth.length || this.feedHealth.length === 0) {
      await this.api.getNewsList('health', { page: page }).then(function (response) {
        addFeed(response.data.data);
        updateLastFeed();
        result.push(response.data.data);
      });
    }
    return result;
  };
}
