/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import axios from 'axios';
import TimeAgo from 'timeago-react';
import { useUserStore } from '../../../store/user';
import React, { useEffect, useState } from 'react';
import { useErrorStore } from '../../../store/error';
import Api from '../../../util/api';
import { useNavigate } from 'react-router-dom';

function CardContent(props) {
  const [voteMethods, setVoteMethods] = useState(null);
  const user = useUserStore((state) => state.user);
  const [vote, setVote] = useState(props.vote);
  const addError = useErrorStore((state) => state.addError);
  const [share, setShare] = useState(true);
  const [bookmark, setBookmark] = useState(false);
  const [isComment, setIsComment] = useState(false);
  const [comment, setComment] = useState([]);
  const [commentInput, setCommentInput] = useState();
  const navigate = useNavigate();

  const api = new Api();

  useEffect(() => {
    getCommentApi();
    if (props.isAuth) {
      try {
        if (props.upvote_user.includes(user._id)) {
          setVoteMethods('upvote');
        } else if (props.downvote_user.includes(user._id)) {
          setVoteMethods('downvote');
        }
      } catch (e) {
        console.log('Error');
      }
    }
  }, []);
  const getCommentApi = async () => {
    api.getComment(props.id).then(function (response) {
      setComment(response.data.data);
    });
  };

  const postAddCommentApi = () => {
    if (props.isAuth) {
      api
        .postAddComment({
          feed_id: props.id,
          body: commentInput,
          user_id: user._id
        })
        .then(function (response) {
          setComment((oldComment) => [response.data.data, ...oldComment]);
          setCommentInput('');
        });
    } else {
      navigate('/login');
    }
  };

  const postAddVoteApi = (method) => {
    axios
      .post('https://indonesia-news-api.vercel.app/vote/news', {
        id: props.id,
        method: method,
        user_id: user._id
      })
      .catch(function (error) {
        addError(error.message);
      });
  };

  const postChangeVoteApi = (method) => {
    axios
      .post('https://indonesia-news-api.vercel.app/vote/news/change', {
        id: props.id,
        method: method,
        user_id: user._id
      })
      .then(function () {
        // setUpvote((prevCheck) => !prevCheck);
      })
      .catch(function (error) {
        addError(error.message);
      });
  };

  const postRemoveVoteApi = (method) => {
    axios
      .post('https://indonesia-news-api.vercel.app/vote/news/remove', {
        id: props.id,
        method: method,
        user_id: user._id
      })
      .then(function () {
        console.log('okee');
      })
      .catch(function (error) {
        addError(error.message);
      });
  };

  const shareModalOpen = (e) => {
    e.preventDefault();
    setShare(!share);
    console.log(share);
  };

  // const addBookmark = (e) => {
  //   e.preventDefault();
  //   let feedStr = JSON.stringify({
  //     description: props.description,
  //     title: props.title,
  //     urlToImage: props.thumbnail,
  //     publishedAt: props.date,
  //     source: props.source_name,
  //     url: props.link
  //   });
  //   console.log(feedStr.replaceAll("\"", "'"));
  //   api
  //     .postAddBookmark({
  //       user_id: user._id,
  //       feed: feedStr
  //     })
  //     .then(function (response) {
  //       console.log(response);
  //       setBookmark(!bookmark);
  //     });
  // };

  const commentModalOpen = (e) => {
    e.preventDefault();
    setIsComment(!isComment);
  };

  const addUpvote = (e, method) => {
    e.preventDefault();
    if (props.isAuth) {
      if (method === 'upvote') {
        if (voteMethods === 'upvote') {
          setVote(vote - 1);
          setVoteMethods(null);
          postRemoveVoteApi(method);
        } else {
          if (voteMethods !== null) {
            setVote(vote === -1 ? vote + 2 : vote + 1);
            setVoteMethods('upvote');
            postChangeVoteApi('upvote');
          } else {
            setVote(vote + 1);
            setVoteMethods('upvote');
            postAddVoteApi(method);
          }
        }
      } else {
        if (voteMethods === 'downvote') {
          setVote(vote + 1);
          setVoteMethods(null);
          postRemoveVoteApi(method);
        } else {
          if (voteMethods !== null) {
            setVote(vote === 1 ? vote - 2 : vote - 1);
            setVoteMethods('downvote');
            postChangeVoteApi('downvote');
          } else {
            setVote(vote - 1);
            setVoteMethods('downvote');
            postAddVoteApi(method);
          }
        }
      }
    } else {
      console.log('anda belum login');
    }
  };

  const commentInputHandler = (e) => {
    e.preventDefault();
    setCommentInput(e.target.value);
  };
  return (
    <>
      <div className="flex ">
        <a
          href={props.link}
          target="_blank"
          className={`border ${
            isComment ? 'border-b-1' : 'border-b-4'
          } p-5 w-full  w-100 bg-white hover:bg-gray-100`}
          rel="noreferrer">
          <div className="flex w-full items-center justify-between border-b pb-3">
            <div className="flex items-center space-x-2">
              <div className="text-1xl font-bold text-slate-700">{props.source_name}</div>
            </div>
            <div className="flex items-center md:space-x-2 space-x-2">
              <button className="rounded-2xl border border-orange-200 bg-orange-100 px-3 py-1 text-xs font-semibold">
                {props.categories}
              </button>
              <div className="text-xs text-neutral-500 md:pl-3">
                <TimeAgo datetime={props.date} locale="id_ID" />
              </div>
              {/* <div className="text-xs text-neutral-500 md:visible invisible">
                <button onClick={addBookmark}>
                  <img
                    // className='bg-neutral-200'
                    src={
                      bookmark
                        ? 'https://img.icons8.com/ios-filled/23/000000/bookmark-ribbon.png'
                        : 'https://img.icons8.com/ios/23/000000/bookmark-ribbon--v1.png'
                    }
                  />
                </button>
              </div> */}
            </div>
          </div>

          <div className="mt-4 mb-6">
            <div className="lg:mb-1  mb-3 text-title lg:text-lg font-bold">{props.title}</div>
            <div className=" hidden sm:block mt-1.5">
              <p className=" lg:mb-3  text-description ">{props.description} ...</p>
            </div>

            <div>
              <img
                className="rounded-md w-full"
                src={props.thumbnail}
                alt="thumbnail"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src =
                    'https://ik.imagekit.io/jh2scbhjww/My_project-1_CLTqwDfNu3.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659872393812';
                }}
              />
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between text-slate-500">
              <div className="inline-flex rounded-md shadow-sm" role="group">
                <button
                  onClick={(e) => addUpvote(e, 'upvote')}
                  type="button"
                  className={`${
                    voteMethods === 'upvote' ? ' bg-neutral-200 border-neutral-400 ' : ''
                  } inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-transparent rounded-l-lg border  hover:bg-neutral-200 hover:border-neutral-400 `}>
                  <img
                    src="https://img.icons8.com/plumpy/24/undefined/up--v1.png"
                    alt="Up-icon"
                    className="w-3.5 h-3.5 mr-1"
                  />
                  {vote}
                  {}
                </button>

                <button
                  onClick={(e) => addUpvote(e, 'downvote')}
                  type="button"
                  className={`${
                    voteMethods === 'downvote' ? ' bg-neutral-200 border-neutral-400 ' : ''
                  } inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-transparent rounded-r-md border  hover:bg-neutral-200 hover:border-neutral-400`}>
                  <img
                    src="https://img.icons8.com/plumpy/24/undefined/down--v1.png"
                    alt="down-icon"
                    className="w-3.5 h-3.5"
                  />
                </button>
                <button
                  onClick={(e) => commentModalOpen(e)}
                  type="button"
                  className=" ml-2 inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-transparent rounded-md border hover:bg-neutral-200 hover:border-neutral-400 ">
                  <div className="flex cursor-pointer items-center transition hover:text-slate-600 ">
                    <img
                      src="https://img.icons8.com/plumpy/24/000000/topic.png"
                      alt="Icon Share"
                      className="w-5 h-5"
                    />
                    &nbsp;{comment.length}
                  </div>
                </button>
              </div>

              <div className="flex space-x-4 md:space-x-8">
                <button
                  id="dropdownDefault"
                  data-dropdown-toggle="dropdown"
                  // class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  type="button"
                  onClick={shareModalOpen}
                  className="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-transparent rounded-md border  ">
                  <div className="flex cursor-pointer items-center transition hover:text-slate-600 ">
                    <img
                      src="https://img.icons8.com/plumpy/24/000000/share.png"
                      alt="Icon Share"
                      className="w-5 h-5"
                    />
                    <span className="text-sm ml-1">Share</span>
                  </div>
                </button>
                <div
                  id="dropdown"
                  //  class={`${
                  //     share ? `top-full opacity-100 visible` : 'top-[110%] invisible opacity-0'
                  //   } origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                  class={`${
                    share ? 'hidden' : ''
                  } z-10 w-44 mt-12 bg-white  rounded divide-y divide-gray-100 shadow   absolute '`}>
                  <ul class="py-1 text-sm text-gray-700 " aria-labelledby="dropdownDefault">
                    <li>
                      <div class="flex items-center pl-3 p-2 hover:bg-gray-100 ">
                        <img
                          src="https://img.icons8.com/color/48/000000/facebook-new.png"
                          className="w-6"
                        />
                        <a href="#" class=" font-bold block py-1 px-1.5  ">
                          Facebook
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="flex items-center pl-3 p-2 hover:bg-gray-100 ">
                        <img
                          src="https://img.icons8.com/color/48/000000/twitter--v1.png"
                          className="w-6"
                        />
                        <a href="#" class=" font-bold block py-1 px-1.5  ">
                          Twitter
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="flex items-center pl-3 p-2 hover:bg-gray-100 ">
                        <img
                          src="https://img.icons8.com/color/48/000000/whatsapp--v1.png"
                          className="w-6"
                        />
                        <a href="#" class=" font-bold block py-1 px-1.5  ">
                          Whatsapp
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
      {isComment && (
        <div className="border-x-2 border-b-4">
          <div class="px-2 py-2  mx-auto  sm:px-5 hover:border-blue-200">
            <form action="#" class="mt-4">
              <label for="comment" class="block">
                <textarea
                  id="comment"
                  onChange={(e) => commentInputHandler(e)}
                  cols="30"
                  rows="3"
                  placeholder="Type your comment..."
                  class="px-3 py-2 border shadow-sm border-gray-300 rounded-md w-full block placeholder:text-gray-400 placeholder-gray-500
          focus:outline-none focus:ring-1 bg-gray-50 focus:ring-blue-600 focus:border-blue-600 text-sm"
                  value={commentInput}
                />
              </label>
              <button
                type="button"
                onClick={postAddCommentApi}
                class="mt-2  inline-flex items-center justify-center text-gray-100 font-medium leading-none
             bg-neutral-900 rounded-md py-2 px-3 border border-transparent transform-gpu hover:-translate-y-0.5 
             transition-all ease-in duration-300 hover:text-gray-200 hover:bg-neutral-700 text-sm">
                Post comment
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 ml-2 rotate-90"
                  viewBox="0 0 20 20"
                  fill="currentColor">
                  <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                </svg>
              </button>
            </form>
            <div class="my-4">
              <small class="text-base font-bold text-gray-700 ml-1">{comment.length} comment</small>
              {comment.length !== 0 ? (
                comment.map((item) => {
                  return (
                    <>
                      <div class=" mt-4">
                        <div class="flex flex-row mx-auto justify-between px-1 py-1">
                          <div class="flex mr-2 ">
                            <div class="items-center justify-center w-12 h-12 mx-auto ">
                              <img
                                alt="profil"
                                src={`https://avatars.dicebear.com/api/miniavs/${item.user_data.username}.svg`}
                                class="elative z-30 inline object-cover w-12 h-12 border-2 border-black rounded-full"
                              />
                            </div>
                          </div>
                          <div class="flex-1  pl-1">
                            <div class="text-base font-semibold text-gray-600">
                              {item.user_data.username}
                              <span class="text-sm font-normal text-gray-500">
                                - {<TimeAgo datetime={item.created_at} locale="id_ID" />}
                              </span>
                            </div>
                            <div class="text-sm text-gray-600">{item.body}</div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <p className="text-xl text-center">Tidak ada komentar</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CardContent;
