/* eslint-disable no-undef */
import axios from 'axios';
import { useErrorStore } from '../../store/error';
import Auth from '../auth';

export default class Api {
  constructor() {
    const auth = new Auth();
    this.api_token = auth.getToken();
    this.client = null;
    this.api_url = 'https://api.bebasinfo.my.id/';
    this.addError = useErrorStore((state) => state.addError);
  }
  init = () => {
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    if (this.api_token !== 'need login') {
      headers.Authorization = `Bearer ${this.api_token}`;
    }
    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 31000,
      headers: headers
    });

    return this.client;
  };
  getNewsList = (category, params) => {
    return this.init()
      .get(`/get/news/${category}`, { params: params })
      .catch((error) => {
        this.addError(error.message);
      });
  };

  getTrandingList = () => {
    return this.init()
      .get(`/get/news/tranding`)
      .catch((error) => {
        this.addError(error.message);
      });
  };

  getBookmark = () => {
    return this.init()
      .get(`/bookmark/62c95a9c7b4fb4f5aeeefb55`)
      .catch((error) => {
        this.addError(error.message);
      });
  };

  getComment = (feed_id) => {
    return this.init()
      .get(`/comment/${feed_id}/all`)
      .catch((error) => {
        this.addError(error.message);
      });
  };

  postAddComment = (params) => {
    return axios
      .post(`${this.api_url}comment/create`, params)

      .catch(function (error) {
        addError(error.message);
      });
  };

  postAddVote = (params) => {
    return this.init()
      .post(`/vote/news`, params)
      .catch((error) => {
        console.log(params);
        console.log(error.message);
        this.addError(error.message);
      });
  };

  postAddBookmark = (params) => {
    console.log(params);
    return this.init()
      .post(`/create/bookmark`, params)
      .catch((error) => {
        console.log(this.api_token);
        this.addError(error.message);
      });
  };

  //   addNewUser = (data) => {
  //     return this.init().post('/users', data);
  //   };
}
