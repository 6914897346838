/* eslint-disable no-undef */

import { useUserStore } from '../store/user';

export default class Auth {
  constructor() {
    this.user = useUserStore((state) => state.user);
  }

  getUser = () => {
    return this.user ? this.user : 'need login';
  };

  getToken = () => {
    // console.log(this.user ? this.user.token : 'need login');
    // return 'okee';
    return this.user ? this.user.token : 'need login';
  };

  isAuth = () => {
    return this.user !== null;
  };
}
