/* eslint-disable no-unused-vars */
import create from 'zustand';

const useStore = create((set) => ({
  error: null,
  addError: (data) => {
    set((state) => ({
      error: data
    }));
  }

  // removeFeed: (data) => {
  //   set((state) => ({
  //     feed: []
  //   }));
  // }

  //   removeStudent: id =>
  //     set(state => ({
  //       students: state.students.filter(student => student.id !== id)
  //     })),
  //   updateStudent: student =>
  //     set(state => ({
  //       students: state.students.map(item => {
  //         if (item.id === student.id) {
  //           return {
  //             ...item,
  //             name: student.name,
  //             section: student.section
  //           };
  //         } else {
  //           return item;
  //         }
  //       })
  //     }))
}));

export const useErrorStore = useStore;
