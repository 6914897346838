/* eslint-disable react/prop-types */
function CardStockItem(props) {
  return (
    <div className="px-2">
      <a
        href={`https://www.google.com/finance/quote/${props.code}:IDX?hl=in`}
        target="_blank"
        className="flex bg-white border w-36  border-gray-300 rounded-xl overflow-hidden items-center justify-start"
        rel="noreferrer">
        <div className="p-4">
          <p className="text-sm line-clamp-1 font-bold">{props.code}</p>
          <p className="text-sm mb-2   mt-2 line-clamp-2">Rp. {props.price}</p>
          <span className="flex bg-blue-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-800">
            <img
              src="https://img.icons8.com/plumpy/24/undefined/up--v1.png"
              alt="Up-icon"
              className="w-3 h-3 mt-0.5 mr-1"
            />{' '}
            {parseFloat(props.change_percent).toFixed(2)}%
          </span>
        </div>
      </a>
    </div>
  );
}

export default CardStockItem;
