/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-unknown-property */
// import "./App.css";
import { Header, Sidebar, RightBar } from '../components/molecule/index';
import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useErrorStore } from '../store/error';
import { HeaderContent } from '../components/atom';
import ErrorHeaderContent from '../components/atom/error_header_content';
import ModalShare from '../components/atom/modal_share';
import ReactModal from 'react-modal';
function Home() {
  const [share, setShare] = useState(null);
  const error = useErrorStore((state) => state.error);

  const closeModal = () => {
    setShare(false);
  };
  return (
    <>
      <div>
        <Header />
        <div className="max-w-6xl mx-auto lg:px-2">
          <div className="grid grid-cols-10   lg:gap-4">
            <div className=" col-span-2 invisible lg:visible ">
              <Sidebar />
            </div>
            <div className=" lg:col-span-5 col-span-10 ">
              {error ? <ErrorHeaderContent /> : <HeaderContent />}

              <Outlet context={[share, setShare]} />
            </div>
            <div className=" col-span-2  invisible lg:visible ">
              <RightBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
