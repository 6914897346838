/* eslint-disable react/prop-types */
import TimeAgo from 'timeago-react'; // var TimeAgo = require('timeago-react');

function CardCommunity(props) {
  return (
    <>
      {/* <HeaderContent/> */}
      <div className="flex ">
        <a
          href={props.link}
          target="_blank"
          className="border border-b-4 p-5 w-full  w-100 bg-white hover:bg-gray-100"
          rel="noreferrer">
          <div className="flex w-full items-center justify-between border-b pb-3">
            <div className="flex items-center space-x-2">
              <div
                className={
                  "h-7 w-7 rounded-full bg-slate-400 bg-[url('https://assets.suara.com/mobile/images/new-images/img-suara.jpg')] bg-cover"
                }
              />
              <div className="text-xl font-bold text-slate-700">{props.author_name}</div>
            </div>
            <div className="flex items-center space-x-8">
              {/* <button class="rounded-2xl border border-orange-200 bg-orange-100 px-3 py-1 text-xs font-semibold">
                {props.categories}
              </button> */}
              <div className="text-xs text-neutral-500">
                <TimeAgo datetime={props.date} locale="id_ID" />
              </div>
            </div>
          </div>

          <div className="mt-4 mb-6">
            {/* <div class="mb-1 text-title lg:text-lg font-bold">{props.title}</div> */}
            <div className="mt-2 mb-4 ml-1">
              {props.children}
              {/* <p className=" mb-3 text-xl text-justify">{props.body}</p> */}
            </div>

            {/* object-contain */}

            {/* <div className=" mx-auto ">
              <div className="flex bg-white border border-gray-300 rounded-xl overflow-hidden items-center justify-start">
                <div className="p-4">
                  <p className="text-sm line-clamp-1 font-bold">
                    {' '}
                    Hati-Hati, Spyware Berbahaya Intai Pengguna Android dan iOS
                  </p>

                  <p className="text-sm mb-2 text-gray-500 mt-1 line-clamp-2">
                    Tim peneliti Google mengungkap, RCS Labs sengaja menginfeksi pengguna HP Android
                    dan iOS dengan spyware mereka.
                  </p>

                  <span className="flex items-center justify-start text-gray-500">
                    <svg
                      className="w-4 h-4 mr-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                        clipRule="evenodd"
                      />
                    </svg>
                    liputan6.com
                  </span>
                </div>
              </div>
            </div> */}
            <div className="bg-white mt-3">
              <img
                className="object-contain max-h-64 w-full"
                src="https://images.unsplash.com/photo-1554629947-334ff61d85dc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&h=1280&q=80"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between text-slate-500">
              <div className="flex space-x-4 md:space-x-8">
                <div className="flex cursor-pointer items-center transition hover:text-slate-600">
                  <img
                    src="https://img.icons8.com/plumpy/24/undefined/up--v1.png"
                    alt="Up-icon"
                    className="w-3.5 h-3.5"
                  />
                  <span className="text-sm pl-1">Upvote</span>
                </div>
                <div className="flex cursor-pointer items-center transition hover:text-slate-600">
                  <img
                    src="https://img.icons8.com/plumpy/24/undefined/down--v1.png"
                    alt="down-icon"
                    className="w-3.5 h-3.5"
                  />
                  <span className="text-sm pl-1">downvote</span>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </>
  );
}

export default CardCommunity;
