/* eslint-disable react/prop-types */
function CardRightSitebar(props) {
  return (
    <>
      <a
        href={props.url}
        target="_blank"
        className="block p-3 mb-3  bg-white rounded-lg border border-gray-200 hover:bg-gray-100"
        style={{ maxWidth: 295 }}
        rel="noreferrer">
        <h5 className="mb-1 text-sm font-bold tracking-tight text-gray-900 ">{props.source}</h5>
        <p className=" text-sm  text-gray-700 text-ellipsis overflow-hidden h-10 ">{props.body}</p>
      </a>
    </>
  );
}

export default CardRightSitebar;
