function CardPost() {
  return (
    <>
      <article className="p-6 mt-4 bg-white sm:p-8 rounded-xl border">
        <div className="flex items-start">
          <div className="">
            <h2 className=" text-lg font-medium sm:text-xl">
              <a href="" className="hover:underline text-xl font-bold text-slate-700">
                Fakta Dibalik Asal Garut Jual Konten PORNO Seharga Rp.300 Ribu Di Medsos !
              </a>
            </h2>

            <p className="mt-1 text-description text-gray-700">
              Selamat pagi gansis.. Garut kembali membuat heboh gan!, yup setelah ramai-ramai kasus
              Vina garut menjadi topik pemberitaan beberapa waktu lalu, kini Garut kembali ramai
              diberitakan, kita bahas sedikit ya...
            </p>

            <div className="mt-4 sm:flex sm:items-center sm:gap-2">
              <p className="mt-2 text-xs font-medium text-gray-500 sm:mt-0">
                20 Juni 2022{' • '}
                <a href="" className="underline hover:text-gray-700">
                  10 Vote
                </a>
              </p>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}

export default CardPost;
