// import "./App.css";
import { Header } from '../components/molecule/index';
import { Outlet } from 'react-router-dom';
function Page() {
  return (
    <>
      <Header />
      <div className="max-w-6xl  mt-10 mx-auto lg:px-2">
        <div className="block p-10 mb-3  bg-white rounded-lg border border-gray-200 ">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Page;
