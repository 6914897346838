import { CategoryItem } from '../../atom/index';
import { useLocation } from 'react-router';

function Sidebar() {
  const { pathname } = useLocation();
  const isBerita = pathname === '/berita' || pathname === '/';
  const isSport = pathname === '/sport';
  const isEconomy = pathname === '/economy';
  const isTeknologi = pathname === '/teknologi';
  const isCelebrity = pathname === '/celebrity';
  const isScience = pathname === '/science';

  const isHealth = pathname === '/health';
  // const isHoax = pathname === '/cekhoax';
  return (
    <>
      <div className="mt-5 fixed">
        <div className=" inline-flex mb-3">
          <div className={'inline-flex w-52  rounded-full '} role="alert">
            <p className="font-semibold text-md">Category</p>
            <img
              src="https://img.icons8.com/external-others-iconmarket/344/external-category-user-experience-others-iconmarket-3.png"
              className="w-6 ml-1"
              alt="Tranding"
            />
          </div>
        </div>
        <CategoryItem category="berita" active={isBerita} />
        <CategoryItem category="sport" active={isSport} />
        <CategoryItem category="economy" active={isEconomy} />
        <CategoryItem category="teknologi" active={isTeknologi} />
        <CategoryItem category="science" active={isScience} />

        {/* <CategoryItem category="lifestyle" active={isLifestyle} /> */}
        {/* <CategoryItem category="football" active={isFootball} /> */}
        <CategoryItem category="celebrity" active={isCelebrity} />
        {/* <CategoryItem category="otomotif" active={isOtomotif} /> */}
        <CategoryItem category="health" active={isHealth} />
        {/* <CategoryItem category="hoax" active={isHoax} /> */}
      </div>
    </>
  );
}

export default Sidebar;
