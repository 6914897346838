/* eslint-disable react/no-unknown-property */
function Badges() {
  return (
    <div className="p-5 mt-4 border">
      <div className=" inline-flex mb-3">
        <div className={'inline-flex w-52  rounded-full '} role="alert">
          <p className="font-semibold text-md">Badges</p>
          <img
            src="https://img.icons8.com/emoji/48/undefined/fire.png"
            className="w-5"
            alt="Tranding"
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <img src="https://res.cloudinary.com/practicaldev/image/fetch/s--ytlCYKyP--/c_limit,f_auto,fl_progressive,q_80,w_375/https://dev-to-uploads.s3.amazonaws.com/uploads/badge/badge_image/22/git-sticker.png" />
        <img src="https://res.cloudinary.com/practicaldev/image/fetch/s--ytlCYKyP--/c_limit,f_auto,fl_progressive,q_80,w_375/https://dev-to-uploads.s3.amazonaws.com/uploads/badge/badge_image/22/git-sticker.png" />
        <img src="https://res.cloudinary.com/practicaldev/image/fetch/s--ytlCYKyP--/c_limit,f_auto,fl_progressive,q_80,w_375/https://dev-to-uploads.s3.amazonaws.com/uploads/badge/badge_image/22/git-sticker.png" />
        <img src="https://res.cloudinary.com/practicaldev/image/fetch/s--ytlCYKyP--/c_limit,f_auto,fl_progressive,q_80,w_375/https://dev-to-uploads.s3.amazonaws.com/uploads/badge/badge_image/22/git-sticker.png" />
      </div>
    </div>
  );
}

export default Badges;
