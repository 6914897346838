/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-key */
// import { useFeedStore } from '../../../../store/index';
// import { useEffect, useState, useRef } from "react";
import React, { useEffect, useState, useRef } from 'react';

// import axios from 'axios';
import { useErrorStore } from '../../../../store/error';
import Api from '../../../../util/api';

function useEffectOnce(effect) {
  const effectFn = useRef(effect);
  const destroyFn = useRef();
  const effectCalled = useRef(false);
  const rendered = useRef(false);
  const [, refresh] = useState(0);

  if (effectCalled.current) {
    rendered.current = true;
  }

  useEffect(() => {
    if (!effectCalled.current) {
      destroyFn.current = effectFn.current();
      effectCalled.current = true;
    }

    refresh(1);

    return () => {
      if (rendered.current === false) return;
      if (destroyFn.current) destroyFn.current();
    };
  }, []);
}

function BookmarkContent() {
  const [page, setPage] = useState(1);
  const [feed, setFeed] = useState([]);

  // const feed = useFeedStore((state) => state.feed);
  // const addFeed = useFeedStore((state) => state.addFeed);
  // const removeFeed = useFeedStore((state) => state.removeFeed);
  const addError = useErrorStore((state) => state.addError);
  const api = new Api();
  const feedNewsData = async () => {
    api
      .getBookmark()
      .then(function (response) {
        console.log(response.data.data);
        setFeed(response.data.data);
        setPage(page + 1);
      })
      .catch(function (error) {
        addError(error.message);
      });
    // await axios
    //   .get(`https://indonesia-news-api.vercel.app/get/news/general?page=${page}`, {
    //     params: {
    //       ID: 12345
    //     }
    //   })
    //   .then(function (response) {
    //     addFeed(response.data.data);
    //     setPage(page + 1);
    //   })
    //   .catch(function (error) {
    //     addError(error.message);
    //   });
  };
  useEffectOnce(() => {
    // removeFeed();
    feedNewsData();
    // console.log(feed);
  }, []);
  return (
    <>
      <div className="p-5">
        <div class=" bg-gray-50  ">
          <div class="max-w-7xl mx-auto">
            <div class="relative group">
              <div class="absolute -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
              <div class="relative px-7 py-6 bg-white ring-1 ring-gray-900/5 rounded-lg leading-none flex items-top justify-start space-x-6">
                <svg class="w-8 h-8 text-Slate-600" fill="none" viewBox="0 0 24 24">
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M6.75 6.75C6.75 5.64543 7.64543 4.75 8.75 4.75H15.25C16.3546 4.75 17.25 5.64543 17.25 6.75V19.25L12 14.75L6.75 19.25V6.75Z"></path>
                </svg>
                <div class="space-y-2">
                  <p class="text-slate-800">Kumpulan berita yang disimpan</p>
                  <a
                    href="https://braydoncoyer.dev/blog/tailwind-gradients-how-to-make-a-glowing-gradient-background"
                    class="block text-indigo-400 group-hover:text-slate-800 transition duration-200">
                    Halaman →
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {feed.map((item) => (
          // <p>{item.feed.title}</p>
          <div class=" mt-3" key={item.feed.entityId}>
            <div class="relative flex flex-col md:flex-row  rounded-xl px-3 max-w-xs max-w-3xl  border border-dark bg-white">
              <div class="w-full  bg-white flex flex-col space-y-2 p-3">
                {/* <div class="flex justify-between item-center">
                  <p class="text-gray-500 font-medium hidden md:block">Vacations</p>
                  <div class="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-yellow-500"
                      viewBox="0 0 20 20"
                      fill="currentColor">
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                    <p class="text-gray-600 font-bold text-sm ml-1">
                      4.96
                      <span class="text-gray-500 font-normal">(76 reviews)</span>
                    </p>
                  </div>
                  <div class="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-pink-500"
                      viewBox="0 0 20 20"
                      fill="currentColor">
                      <path
                        fill-rule="evenodd"
                        d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div class="bg-gray-200 px-3 py-1 rounded-full text-xs font-medium text-gray-800 hidden md:block">
                    Superhost
                  </div>
                </div> */}
                {/* <h3 class="font-black text-gray-800 md:text-3xl text-xl">
                  The Majestic and Wonderful Bahamas
                </h3> */}
                <div className="text-gray-900 font-bold text-title mb-2">{item.feed.title}</div>
                <p class="md:text-lg text-gray-500 text-base">{item.feed.description}</p>
                {/* <button
                  // class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  type="button"
                  className=" w-24  inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-transparent rounded-md border  ">
                  <div className="flex cursor-pointer items-center transition hover:text-slate-600 ">
                    <img
                      src="https://img.icons8.com/plumpy/24/000000/share.png"
                      alt="Icon Share"
                      className="w-5 h-5"
                    />
                    <span className="text-sm ml-1">Remove</span>
                  </div>
                </button> */}
                <button class="text-xl w-20 mt-2 font-black text-gray-800 mt-4">Remove </button>
              </div>
            </div>
          </div>
          // <div className=" w-full lg:max-w-full lg:flex  mt-4 " key={item.feed.entityId}>
          //   <div
          //     className="h-48 lg:h-auto lg:w-48 flex-none bg-cover bg-center rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden "
          //     style={{
          //       backgroundImage: `url(${
          //         item.feed.urlToImage === null
          //           ? 'http://via.placeholder.com/640x360'
          //           : item.feed.urlToImage
          //       })`
          //     }}
          //     title="Mountain"
          //   />
          //   <div className="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
          //     <div className="mb-8">
          //       <div className="text-gray-900 font-bold text-title mb-2">{item.feed.title}</div>

          //       {/* <p className="text-gray-700 text-sm">{item.feed.description}</p> */}
          //     </div>
          //   </div>
          // </div>
        ))}
      </div>

      {/* <HeaderContent />
      {feed.length === 0 ? (
        <>
          <CardSkeleton /> <CardSkeleton /> <CardSkeleton /> <CardSkeleton />
        </>
      ) : (
        <InfiniteScroll
          dataLength={feed.length}
          next={feedNewsData}
          hasMore
          loader={
            <div className="text-center mt-10 mb-10">
              <h4 className="font-bold text-xl">Loading...</h4>
            </div>
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          {feed.map((feed, i) => (
            <CardContent
              key={i}
              link={feed.url}
              source_name={feed.source}
              description={feed.description}
              title={feed.title}
              thumbnail={feed.urlToImage}
              categories={"Berita"}
              date={feed.publishedAt}
            />
          ))}
        </InfiniteScroll>
      )} */}
    </>
  );
}

export default BookmarkContent;
