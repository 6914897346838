/* eslint-disable no-unused-vars */
import create from 'zustand';
import { persist } from 'zustand/middleware';

const useStore = create(
  persist((set) => ({
    user: null,
    addUser: (data) => {
      set((state) => ({
        user: data
      }));
    },

    removeFeed: (data) => {
      set((state) => ({
        feed: []
      }));
    }
  }))
);

export const useUserStore = useStore;
