/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import { useState } from 'react';
import { Menu } from '@headlessui/react';

import { Link, useLocation } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { useUserStore } from '../../../store/user';
function Header() {
  const user = useUserStore((state) => state.user);

  const [dropdownOpen, setdropdownOpen] = useState(false);
  const { pathname } = useLocation();
  const isCommunity = pathname === '/community';

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <nav className="bg-black sticky top-0 z-50">
      <div className="max-w-6xl mx-auto px-2  lg:px-2">
        <div className="relative flex items-center justify-between h-14">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false">
              <span className="sr-only">Open main menu</span>
            </button>
          </div>
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <img
                className="h-6 w-auto"
                src="https://ik.imagekit.io/jh2scbhjww/Green_Black___White_Bold_Monstera_Plant_Nursery_Plant_Logo__500___131_px___1___1__0xLuEYVgZb.png?ik-sdk-version=javascript-1.4.3&updatedAt=1656344681856"
                alt="Workflow"
              />
              {/* <img className=" h-6 w-auto" src="https://ik.imagekit.io/jh2scbhjww/Green_Black___White_Bold_Monstera_Plant_Nursery_Plant_Logo__500___131_px___1___1__0xLuEYVgZb.png?ik-sdk-version=javascript-1.4.3&updatedAt=1656344681856" alt="Workflow"/>  */}
            </div>
            {/* <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4">
                <a
                  href="/"
                  class={
                    isCommunity
                      ? 'text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'
                      : 'bg-gray-800 text-white px-3 py-2 rounded-md text-sm font-medium'
                  }
                  aria-current="page">
                  Berita
                </a>
                <a
                  href="/community"
                  class={
                    isCommunity
                      ? 'bg-gray-800 text-white px-3 py-2 rounded-md text-sm font-medium'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'
                  }>
                  Community
                </a>
              </div>
            </div> */}
          </div>
          <div class="block lg:hidden">
            <Menu>
              <Menu.Button>
                {' '}
                <button
                  id="nav-toggle"
                  class="flex items-center pr-1 py-2 text-white border-white hover:text-white hover:border-white">
                  <div class="space-y-2">
                    <span class="block w-8 h-0.5 bg-white"></span>
                    <span class="block w-8 h-0.5 bg-white"></span>
                    <span class="block w-5 h-0.5 bg-white"></span>
                  </div>
                </button>
              </Menu.Button>
              <Menu.Items>
                {/* <div className="h-screen">
                  <h2 class="text-xl text-white">Full-screen overlay navigation menu example</h2>
                </div> */}
                <div
                  class={`top-full opacity-100 visible origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabindex="-1">
                  <div className="py-1" role="none">
                    <Menu.Item>
                      <Link
                        to={`/login`}
                        className="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0">
                        Login
                        <br />
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        to={`/register`}
                        className="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0">
                        Register
                        <br />
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        to={`/`}
                        className="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0">
                        Home
                        <br />
                      </Link>
                    </Menu.Item>
                    <hr />
                    <Menu.Item>
                      <Link
                        to={`/sport`}
                        className="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0">
                        Sport
                        <br />
                      </Link>
                    </Menu.Item>
                    <hr />
                    <Menu.Item>
                      <Link
                        to={`/berita`}
                        className="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0">
                        Berita
                        <br />
                      </Link>
                    </Menu.Item>
                    <hr />
                    <Menu.Item>
                      <Link
                        to={`/economy`}
                        className="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0">
                        Economy
                        <br />
                      </Link>
                    </Menu.Item>
                    <hr />
                    <Menu.Item>
                      <Link
                        to={`/teknologi`}
                        className="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0">
                        Teknologi
                        <br />
                      </Link>
                    </Menu.Item>
                    <hr />
                    {/* <Menu.Item>
                      <Link
                        to={`/lifestyle`}
                        className="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0">
                        Lifestyle
                        <br />
                      </Link>
                    </Menu.Item>
                    <hr /> */}
                    <Menu.Item>
                      <Link
                        to={`/football`}
                        className="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0">
                        Football
                        <br />
                      </Link>
                    </Menu.Item>
                    <hr />
                    <Menu.Item>
                      <Link
                        to={`/celebrity`}
                        className="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0">
                        Celebrity
                        <br />
                      </Link>
                    </Menu.Item>
                    <hr />
                    <Menu.Item>
                      <Link
                        to={`/otomotif`}
                        className="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0">
                        Otomotif
                        <br />
                      </Link>
                    </Menu.Item>
                    <hr />
                    <Menu.Item>
                      <Link
                        to={`/science`}
                        className="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0">
                        Science
                        <br />
                      </Link>
                    </Menu.Item>
                    <hr />
                    <Menu.Item>
                      <Link
                        to={`/health`}
                        className="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0">
                        Health
                        <br />
                      </Link>
                    </Menu.Item>
                    <hr />
                  </div>
                </div>
                {/* <Menu.Item>
                {({ active }) => (
                  <a className={`${active && 'bg-blue-500'}`} href="/account-settings">
                    Account settings
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a className={`${active && 'bg-blue-500'}`} href="/account-settings">
                    Documentation
                  </a>
                )}
              </Menu.Item>
              <Menu.Item disabled>
                <span className="opacity-75">Invite a friend (coming soon!)</span>
              </Menu.Item> */}
              </Menu.Items>
            </Menu>
          </div>

          <div className="hidden sm:block absolute inset-y-0 right-0 items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 ">
            {user === null ? (
              <>
                <Link
                  to={`/login`}
                  class={
                    'text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-lg font-medium'
                  }>
                  Login
                </Link>

                <Link
                  to={`/register`}
                  class={
                    'text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-lg font-medium'
                  }>
                  Register
                </Link>
              </>
            ) : (
              <>
                <Menu>
                  <div class="flex-initial  mt-1 ">
                    <div class="flex justify-end items-center relative  ">
                      <div class="block ">
                        <div class="inline relative ">
                          <Menu.Button>
                            <button
                              type="button"
                              class="inline-flex items-center relative px-2 border rounded-full hover:shadow-lg">
                              <div class="block flex-grow-0 flex-shrink-0 h-10 pr-1">
                                <img
                                  className="h-7 w-7 mt-1.5 rounded-full bg-white "
                                  src={`https://avatars.dicebear.com/api/miniavs/${user.username}.svg`}
                                  alt=""
                                />
                              </div>
                              <div class="pl-3 pr-1">
                                <p className="text-white">{user.username}</p>
                              </div>
                            </button>
                          </Menu.Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Menu.Items>
                    <div
                      class={`top-full opacity-100 visible origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="menu-button"
                      tabindex="-1">
                      <div className="py-1" role="none">
                        <Menu.Item>
                          <a
                            href="#"
                            className="text-gray-700 block px-4 py-2 text-sm"
                            role="menuitem"
                            tabindex="-1"
                            id="menu-item-0">
                            Account
                            <br />
                          </a>
                        </Menu.Item>

                        <hr />
                        <Menu.Item>
                          <button
                            type="button"
                            onClick={logout}
                            className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                            role="menuitem"
                            tabindex="-1"
                            id="menu-item-3">
                            Sign out
                          </button>
                        </Menu.Item>
                      </div>
                    </div>
                  </Menu.Items>
                </Menu>

                {/* <div
                  class={`${
                    dropdownOpen ? `top-full opacity-100 visible` : 'top-[110%] invisible opacity-0'
                  } origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabindex="-1">
                  <div className="py-1" role="none">
                    <a
                      href="#"
                      className="text-gray-700 block px-4 py-2 text-sm"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-0">
                      Account
                      <br />
                    </a>
                    <hr />

                    <button
                      type="button"
                      onClick={logout}
                      className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-3">
                      Sign out
                    </button>
                  </div>
                </div> */}
              </>
            )}
          </div>
        </div>
      </div>

      {/* <div class="">
   
      </div> */}
    </nav>
  );
}

export default Header;
