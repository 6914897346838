import { HeaderContent } from '../components/atom';
// import { useFeedStore } from "../../store/index";
// import { useEffect, useState, useRef } from "react";
import React, { useEffect, useState, useRef } from 'react';

import axios from 'axios';
import { useFeedStore } from '../store';
import { CardCommunity, Header, RightBar, Sidebar } from '../components/molecule';
import { useErrorStore } from '../store/error';
import CardComunityCategory from '../components/molecule/card_comunity_category';

function useEffectOnce(effect) {
  const effectFn = useRef(effect);
  const destroyFn = useRef();
  const effectCalled = useRef(false);
  const rendered = useRef(false);
  const [, refresh] = useState(0);

  if (effectCalled.current) {
    rendered.current = true;
  }

  useEffect(() => {
    if (!effectCalled.current) {
      destroyFn.current = effectFn.current();
      effectCalled.current = true;
    }

    refresh(1);

    return () => {
      if (rendered.current === false) return;
      if (destroyFn.current) destroyFn.current();
    };
  }, []);
}

function Community() {
  const [page, setPage] = useState(1);
  // const feed = useFeedStore((state) => state.feed);
  const addFeed = useFeedStore((state) => state.addFeed);
  const removeFeed = useFeedStore((state) => state.removeFeed);
  const addError = useErrorStore((state) => state.addError);

  // const handleClick = (body, categories) => {
  //   axios
  //     .post('https://indonesia-news-api.vercel.app/community/create', {
  //       user_id: '62bf0eca9dc4bab5a5a28e7b',
  //       body: body,
  //       category: categories
  //     })
  //     .then(function () {
  //       addFeed([
  //         {
  //           _id: '62c07942a6ffc32edb90727b',
  //           user_id: '62bf0eca9dc4bab5a5a28e7b',
  //           body: body,
  //           category: categories,
  //           created_at: '2022-07-02T16:58:42.946Z'
  //         }
  //       ]);
  //     })
  //     .catch(function (error) {
  //       addError(error.message);
  //     });
  // };
  const feedNewsData = async () => {
    await axios
      .get(`https://indonesia-news-api.vercel.app/community/all?page=${page}`, {
        params: {
          ID: 12345
        }
      })
      .then(function (response) {
        addFeed(response.data.data);
        setPage(page + 1);
      })
      .catch(function (error) {
        addError(error.message);
      });
  };
  useEffectOnce(() => {
    removeFeed();
    feedNewsData();
  }, []);

  return (
    <>
      <Header />
      <div className="max-w-6xl mx-auto lg:px-2">
        <div className="grid grid-cols-10   lg:gap-4">
          <div className=" col-span-2 invisible lg:visible ">
            <Sidebar />
          </div>
          <div className=" lg:col-span-5 col-span-10 ">
            <HeaderContent />
            {/* <ComunityInput handleClick={handleClick} /> */}
            <CardComunityCategory />

            <CardCommunity
              key="asdasdasdasd"
              date="2022-07-07T14:53:07.441Z"
              author_name="The MovieDB">
              <h1 className="mb-2  font-bold text-title">
                Fakta Dibalik Asal Garut Jual Konten PORNO Seharga Rp.300 Ribu Di Medsos !
              </h1>
              <p className="text-description">
                Selamat pagi gansis.. Garut kembali membuat heboh gan!, yup setelah ramai-ramai
                kasus Vina garut menjadi topik pemberitaan beberapa waktu lalu, kini Garut kembali
                ramai diberitakan, kita bahas sedikit ya...
              </p>
            </CardCommunity>

            {/* {feed.map((item) => (
              <CardCommunity
                author_name="Muhammad Hajid Al Akhtar"
                key={item._id}
                date={item.created_at}>
                {item.body}
              </CardCommunity>
            ))} */}
          </div>
          <div className=" col-span-2  invisible lg:visible ">
            <RightBar />
          </div>
        </div>
      </div>
    </>
    // <>
    //   <HeaderContent />
    //   {feed.length === 0 ? (
    //     <>
    //       <CardSkeleton /> <CardSkeleton /> <CardSkeleton /> <CardSkeleton />
    //     </>
    //   ) : (
    //     <InfiniteScroll
    //       dataLength={feed.length}
    //       next={feedNewsData}
    //       hasMore
    //       loader={
    //         <div className="text-center mt-10 mb-10">
    //           <h4 className="font-bold text-xl">Loading...</h4>
    //         </div>
    //       }
    //       endMessage={
    //         <p style={{ textAlign: "center" }}>
    //           <b>Yay! You have seen it all</b>
    //         </p>
    //       }
    //     >
    //       {feed.map((feed, i) => (
    //         <CardContent
    //           key={i}
    //           link={feed.url}
    //           source_name={feed.source}
    //           description={feed.description}
    //           title={feed.title}
    //           thumbnail={feed.urlToImage}
    //           categories={"Berita"}
    //           date={feed.publishedAt}
    //         />
    //       ))}
    //     </InfiniteScroll>
    //   )}
    // </>
  );
}

export default Community;

// // import { useLocation } from "react-router-dom";
// import { HeaderContent } from "../components/atom";
// import {
//   Header,
//   Sidebar,
//   RightBar,
//   CardCommunity,
// } from "../components/molecule/index";
// // import { Outlet } from "react-router-dom";
// function Community() {
//   // https://indonesia-news-api.vercel.app/community/all

//   return (
//     <>
//       <Header />
//       <div className="max-w-6xl mx-auto lg:px-2">
//         <div className="grid grid-cols-10   lg:gap-4">
//           <div className=" col-span-2 invisible lg:visible ">
//             <Sidebar />
//           </div>
//           <div className=" lg:col-span-5 col-span-10 ">
//             <HeaderContent />

//             <div className="border border-b-4 p-5 w-full  w-100 bg-white ">
//               <textarea
//                 className="bg-gray-200 w-full rounded-lg shadow border p-2"
//                 rows="5"
//                 placeholder="Berbicara lah secukupnya"
//                />

//               <div className="w-full flex flex-row flex-wrap mt-3">
//                 <div className="w-1/3">
//                   <select className="w-full p-2 rounded-lg bg-gray-200 shadow border float-left">
//                     <option>Berita</option>
//                     <option>Sport</option>
//                   </select>
//                   {/* <img src="https://img.icons8.com/plumpy/24/000000/image.png"></img> */}
//                 </div>
//                 <div className="w-2/3">
//                   <button
//                     type="button"
//                     className="float-right bg-neutral-900 hover:bg-neutral-700 text-white p-2 rounded-md"
//                   >
//                     Submit
//                   </button>
//                 </div>
//               </div>
//             </div>

//             <CardCommunity />
//             <CardCommunity />
//             <CardCommunity />
//             <CardCommunity />
//           </div>
//           <div className=" col-span-2  invisible lg:visible ">
//             <RightBar />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Community;
