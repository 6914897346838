/* eslint-disable react/prop-types */
// import { useEffect,  } from "react";
import React from 'react';

function CardComunityCategory() {
  return (
    <>
      <div className="flex ">
        <div
          target="_blank"
          className="border border-b-4 p-5 w-full  w-100 bg-white hover:bg-gray-100"
          rel="noreferrer">
          <div className="flex w-full items-center justify-between ">
            <div className="flex items-center space-x-2">
              <div className="text-1xl font-bold text-slate-700">asdasd</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardComunityCategory;
