/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
function CategoryItem(props) {
  const [category, setCategory] = useState({
    bookmark: {
      title: 'My Bookmark',
      icon: 'bookmark--v1.png'
    },
    home: {
      title: 'Home',
      icon: 'home--v1.png'
    },
    sport: {
      title: 'Sport',
      icon: 'tennis-2.png'
    },

    berita: {
      title: 'Berita',
      icon: 'newspaper-.png'
    },
    economy: {
      title: 'Economy',
      icon: 'economic-growth-.png'
    },
    teknologi: {
      title: 'Teknologi',
      icon: 'greentech.png'
    },
    lifestyle: {
      title: 'Lifestyle',
      icon: 'airport-building.png'
    },
    football: {
      title: 'Football',
      icon: 'football2.png'
    },
    celebrity: {
      title: 'Celebrity',
      icon: 'micro.png'
    },
    otomotif: {
      title: 'Otomotif',
      icon: 'car--v1.png'
    },
    science: {
      title: 'Science',
      icon: 'lab-items.png'
    },
    health: {
      title: 'Health',
      icon: 'healthy-food.png'
    },
    hoax: {
      title: 'Cek Hoax',
      icon: 'search--v1.png'
    }
  });
  return (
    <>
      {/* <Link to={{ pathname: '/foo', query: { the: 'query' } }}>asdas<Link/> */}
      <a href={`/${props.category}`}>
        <div className="mt-0.5 w-100">
          <button
            className={`inline-flex w-48   items-center py-1 px-1 pr-4 text-sm text-gray-700   ${
              props.active ? ' bg-orange-100 ' : 'bg-white '
            }rounded-full `}>
            <span
              className={`text-xs ${
                props.active ? 'bg-white ' : 'bg-gray-200 '
              } rounded-full text-white px-1.5 py-1.5 mr-2`}>
              <img
                src={require('./icon/' + eval(`category.${props.category}.icon`))}
                alt="category-icon"
                className="w-5"
              />
            </span>{' '}
            <span className="text-sm font-semibold">
              {eval(`category.${props.category}.title`)}
            </span>
          </button>
        </div>
      </a>
    </>
  );
}
// dark:bg-gray-800 dark:text-white
export default CategoryItem;
